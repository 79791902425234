<template>
  <container fluid>
    <CToaster position="top-right" :autohide="1500">
      <template v-for="(toast, index) in toasts">
        <CToast
            :key="'toast' + toast.text + index"
            :show="true"
            :header="toast.header === '' ? null : toast.header"
        >
          {{ toast.text }}
        </CToast>
      </template>
    </CToaster>
    <CRow>
      <!-- Günlük Dakika Seçenekleri -->
      <CCol class="col-lg-3 col-md-6 col-12">
        <CCard>
          <CCardBody>
            <CDataTable
                class="mb-0 table-outline"
                hover
                :items="minperdayItems"
                :fields="minperdayFields"
                head-color="light"
                :items-per-page="10"
            >
              <td slot="minperday" slot-scope="{item}">
                <div>{{ item.minperday }}</div>
                <div class="small text-muted">
                </div>
              </td>
              <td slot="manage">
                <CButton @click="showAlert" color="danger">
                  <CIcon name="cil-x"/>
                </CButton>
              </td>
            </CDataTable>
            <CInput
                type="text"
                placeholder="Dakika Ekleyin"
            >
              <template #append>
                <CButton @click="addMinPerDayToPackage" color="info">Ekle</CButton>
              </template>
            </CInput>
          </CCardBody>
        </CCard>
      </CCol>
      <!-- Haftalık Gün Seçenekleri -->
      <CCol class="col-lg-3 col-md-6 col-12">
        <CCard>
          <CCardBody>
            <CDataTable
                class="mb-0 table-outline"
                hover
                :items="dayperweekItems"
                :fields="dayperweekFields"
                head-color="light"
                :items-per-page="10"
            >
              <td slot="dayperweek" slot-scope="{item}">
                <div>{{ item.dayperweek }}</div>
                <div class="small text-muted">
                </div>
              </td>
              <td slot="manage">
                <CButton @click="showAlert" color="danger">
                  <CIcon name="cil-x"/>
                </CButton>
              </td>
            </CDataTable>
            <CInput
                type="text"
                placeholder="Gün Ekleyin"
            >
              <template #append>
                <CButton type="submit" color="info">Ekle</CButton>
              </template>
            </CInput>
          </CCardBody>
        </CCard>
      </CCol>
      <!-- Paket Süresi Seçenekleri -->
      <CCol class="col-lg-5 col-12">
        <CCard>
          <CCardBody>
            <CDataTable
                class="mb-0 table-outline"
                hover
                :items="packetoptionsItems"
                :fields="packetoptionsFields"
                head-color="light"
                :items-per-page="10"
            >
              <td slot="minperday" slot-scope="{item}">
                <div>{{ item.minperday }}</div>
                <div class="small text-muted">
                </div>
              </td>
              <td slot="minperday" slot-scope="{item}">
                <div>{{ item.minperday }}</div>
                <div class="small text-muted">
                </div>
              </td>
              <td slot="minperday" slot-scope="{item}">
                <div>{{ item.minperday }}</div>
                <div class="small text-muted">
                </div>
              </td>
              <td slot="manage">
                <CButton color="success" class="mr-1">
                  <CIcon name="cil-pencil"/>
                </CButton>
                <CButton @click="showAlert" color="danger">
                  <CIcon name="cil-x"/>
                </CButton>
              </td>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- Paket ekleme / düzenleme-->
    <CRow>
      <CCol class="col-12 col-lg-6">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol class="col-12 col-md-6">
                <CInput
                    placeholder="Paket Süresinin ismi"
                    description="ex. 1 Aylık"
                />
              </CCol>
              <CCol col="12" md="3">
                <CInput
                    placeholder="Paket Süresi"
                    description="ex. 4"
                />
              </CCol>
              <CCol col="12" md="3">
                <CSelect
                    horizontal
                    :options="options"
                    placeholder="Günlük"
                    custom
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol class="col-12 col-md-6 mt-3">
                <CInput label="İndirim Oranı" vertical placeholder="İndirim Oranı" description="ex. 35">
                  <template #append-content>
                    <CIcon color="info" name="cis-percent"/>
                  </template>
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12">
                <CInput
                    label="Ek Açıklama Yazısı"
                    placeholder="Ek Açıklama Yazısı"
                    description="ex. Lorem ipsum dolor sit amet"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol class="col-12 col-md-6" >
                <CButton @click="showToast()" color="info">Değişiklikleri Kaydet</CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </container>
</template>

<script>
import {SubscriptionAdministration} from "@/services/api-service";

export default {
  name: 'Packets',
  components: {},
  data() {
    return {
      minutePerDayOption: '',
      minutePerDayOptionFromServer: '',
      toasts: [],
      options: ['Haftalık', 'Aylık', 'Yıllık'],
      minperdayItems: [
        {
          minperday: '15 Dakika',
        },
        {
          minperday: '30 Dakika',
        },
        {
          minperday: '60 Dakika',
        },
        {
          minperday: '90 Dakika',
        },
      ],
      dayperweekItems: [
        {
          dayperweek: '1 Gün',
        },
        {
          dayperweek: '2 Gün',
        },
        {
          dayperweek: '3 Gün',
        },
        {
          dayperweek: '4 Gün',
        },
      ],
      packetoptionsItems: [
        {
          period: '1 Aylık (1 Ay)',
          description: 'Açıklama Yazısı',
          discountrate: '%10 İndirim'
        },

      ],
      minperdayFields: [
        {key: 'minperday', label: 'Günlük Dakika Seçenekleri'},
        {key: 'manage', label: ' '},
      ],
      dayperweekFields: [
        {key: 'dayperweek', label: 'Haftalık Gün Seçenekleri'},
        {key: 'manage', label: ' '},
      ],
      packetoptionsFields: [
        {key: 'period', label: 'Haftalık Gün Seçenekleri'},
        {key: 'description', label: ' '},
        {key: 'discountrate', label: ' '},
        {key: 'manage', label: ' '},
      ],
    }
  },
  methods: {
    async addMinPerDayToPackage() {
      try {
        await SubscriptionAdministration.addMinutesPerDayOptionToSubscriptionPackages(this.minutePerDayOption);
        this.minutePerDayOptionFromServer = this.minutePerDayOption
        this.minperdayItems = [...this.minperdayItems]
        this.minutePerDayOption = '';
      } catch (e) {
        console.log(e)
      }

    },
    color(value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    showAlert() {
      this.$swal({
        title: 'Emin misiniz?',
        text: "Yaptığınız İşlem Geri Alınamayacaktır!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, Sil'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.fire(
              'Silindi!',
              'İşlem Başarıyla Gerçekleştirildi!',
              'success'
          )
        }
      });
    },
    showToast: function (text = 'Değişiklikler başarıyla kaydedildi.', header = 'Bilgi') {
      this.toasts.push({text, header});
    }
  }
}
</script>

